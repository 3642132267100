import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "components/public/userIndex";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import PayWallet from "./paywallet";
import MyScore from "./myScore";

class Wallet extends Component {
    componentDidMount() {
        this.props.getProfitByMonth({ type: "date" });
    }

    changeTab = (key) => {
        this.props.getProfitByMonth({ type: key });
    };

    render() {
        return (
            <PageBox>
                <Breadcrumb style={{ paddingTop: 20 }}>
                    <Breadcrumb.Item>
                        <a href="/">首页</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/user/index">会员中心</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>钱包</Breadcrumb.Item>
                </Breadcrumb>
                <div className="user-public">
                    <UserPub />
                </div>
                <div className="user-center" id="medalpage">
                    <div className="user-left">
                        <UserLeft path={this.props.history.location.pathname} />
                    </div>
                    <div className="user-right">
                        {/* 充值 */}
                        <div className="main">
                            <PayWallet />
                        </div>
                        {/* 积分兑换 */}
                        <div className="main">
                            <MyScore />
                        </div>
                    </div>
                </div>
            </PageBox>
        );
    }
}

export default connect(
    (state) => state,
    (dispatch) => bindActionCreators(actionsCreators, dispatch)
)(Wallet);
