import Type from "./type";

import Conf from "../common/config";
import { HashRouter } from "react-router-dom";
import { userInfo, showModal } from "./index";
import { createPublicOrder } from "./order";
import { message, Modal } from "antd";
// import {Toast} from 'antd-mobile'
import Axios from "./request";
import { setToken, clearToken } from "common/env";

const {
    USER_LOGIN,
    GLOBAL_SHOW_MODAL,
    USER_PROFIT,
    USER_INFO,
    USER_HOME,
    USER_PUBLIC,
    USER_RELATION,
    USER_GAME,
    USER_TEAM,
    VIP_CARD,
    CITYS,
    PROFIT_MONTH,
    OPENID,
} = Type;

/**
 * 会员登录
 * @param {phone,phoneCode,type='phone'||username,password,type='account'}
 * @return 会员信息
 */
export function login(args) {
    clearToken();
    return (dispatch) => {
        Axios.post(Conf.api + "/v2/login/login", {
            ...args,
        }).then((res) => {
            const { data, code, msg } = res.data;
            if (code === 200) {
                localStorage.setItem("uid", data.user_id);
                setToken(data.token);
                //刷新页面
                window.location.reload();
            } else {
                message.error(msg);
            }
        });
    };
}

/**
 * 注册
 * @param {*} args
 */
export function reg(args) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/reg", {
            ...args,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: GLOBAL_SHOW_MODAL, showModal: null });
                localStorage.setItem("uid", res.data.data.user_id);
                window.location.href = "/#/reg-success";
            }
        });
    };
}

/**
 * 验证用户微信是否已经绑定手机号
 * @param {*} args {code:code}
 */
export function checkwechat(args) {
    return (dispatch) => {
        Axios.get(Conf.api + "/login/checkwechat?" + args).then((res) => {
            if (!res.data.code) {
                localStorage.setItem("uid", res.data.data.user_id);
                message.info("登陆成功");
                window.location.href = "/";
            } else {
                if (res.data.code === 201) {
                    dispatch({ type: OPENID, openid: res.data.data.openid });
                } else {
                    window.location.href = "/";
                }
            }
        });
    };
}

/**
 * 会员退出登录
 * @param uid 用户ID
 */
export function logout() {
    return (dispatch) => {
        localStorage.removeItem("uid");
        dispatch({ type: USER_LOGIN, userInfo: null });
        let router = new HashRouter();
        router.history.push("/");
        window.location.reload();
    };
}

/**
 * 获取用户信息
 */
export function myUserInfo() {
    return userInfo();
}

/**
 * 发送验证码
 */
export function sendSms(args) {
    return (dispatch) => {
        Axios.post(Conf.api + "/v2/login/sendSms", {
            ...args,
        }).then((res) => {
            if (!res.data.code) {
                message.info("发送成功");
            }
        });
    };
}

/**
 * 运营官注册
 * @param {*} args
 */
export function regYYG(args) {
    let that = this;
    return (dispatch) => {
        Axios.post(Conf.api + "/login/regyyg", {
            ...args,
        }).then((res) => {
            if (!res.data.code) {
                localStorage.setItem("uid", res.data.data.user_id);
                that.upgradeVip({ type: 2 });
            }
        });
    };
}

/**
 * 密码重设
 * @param {*} arg
 */
export function forget(args) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/forget", {
            ...args,
        }).then((res) => {
            if (!res.data.code) {
                message.info("修改成功");
                dispatch({ type: GLOBAL_SHOW_MODAL, showModal: null });
            }
        });
    };
}

/**
 * 登录,注册，找回密码弹窗
 * @param arg='login'
 */
export function showLogin(arg) {
    return { type: GLOBAL_SHOW_MODAL, showModal: arg };
}
export function closeDialog() {
    return { type: GLOBAL_SHOW_MODAL, showModal: null };
}
/**
 * 升级会员弹窗
 */
export function upgradeUser(arg) {
    return { type: GLOBAL_SHOW_MODAL, showModal: arg };
}

/**
 * 会员权益
 */
export function getUserProfit() {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/profit").then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_PROFIT, userProfit: res.data.data });
            }
        });
    };
}

/**
 * 运营官权益
 */
export function yygprofit() {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/yygprofit").then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_PROFIT, yygprofit: res.data.data });
            }
        });
    };
}

/**
 * 设置用户所在的城市
 */
export function setCity(params) {
    return (dispatch) => {
        Axios.post(Conf.api + "/area/setcity", params).then((res) => {
            if (!res.data.code) {
                message.info(res.data.msg);
            }
        });
    };
}

/**
 * 获取城市列表
 */
export function getCitys(args) {
    return (dispatch) => {
        Axios.post(Conf.api + "/area/lst", args).then((res) => {
            if (!res.data.code) {
                dispatch({ type: CITYS, citys: res.data.data });
            }
        });
    };
}

/**
 * 获取用户钱包余额
 * @param {*} data
 */
export function wallet(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/v3/wallet/walletInfo", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_INFO, wallet: res.data.data });
            }
        });
    };
}

/**
 * 用户的个人主页
 * @param {*} data
 */
export function userHomePage(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/uhome", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_HOME, uHomePage: res.data.data });
            }
        });
    };
}

//会员公开主页的内容
export function userPublic(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/userpublic", data).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_PUBLIC, user_public: res.data.data });
            }
        });
    };
}

/**
 * 发起添加好友事件
 * @param {*} data
 */
export function addFriend(data) {
    let that = this;
    return (dispatch) => {
        Axios.post(Conf.api + "/friend/add", data).then((res) => {
            if (!res.data.code) {
                message.info(res.data.data);
            }
            that.userPublic({ id: data.id });
        });
    };
}

/**
 * 会员和我的关系
 * @param {*} data id,uid
 */
export function getRelation(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/user/relation", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_RELATION, relation: res.data.data });
            }
        });
    };
}

/**
 * 用户的参赛记录
 * @param {*} data
 */
export function userGame(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/usergame", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_GAME, userGame: res.data.data });
            }
        });
    };
}

/**
 * 会员参团记录
 * @param {*} data
 */
export function userTeam(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/userTeam", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_TEAM, userTeam: res.data.data });
            }
        });
    };
}

/**
 * 用户配置信息
 * @param {*} data
 */
export function setting() {
    return (dispatch) => {
        Axios.post(Conf.api + "/v2/user/setting").then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_TEAM, setting: res.data.data });
            }
        });
    };
}

/**
 * 会员等级说明
 * @returns
 */
export function getUserLevels() {
    return (dispatch) => {
        Axios.post(Conf.api + "/user/levels").then((res) => {
            if (!res.data.code) {
                dispatch({ type: USER_GAME, userLevels: res.data.data });
            }
        });
    };
}

export function updateSetting(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/user/updatesetting", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                message.info("更新成功");
            }
        });
    };
}

/**
 * 提现金额
 * @param {*} data
 */
export function scoreToMoney(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/v3/score/exchange", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                window.location.reload();
            }
            message.info(res.data.msg);
        });
    };
}

/**
 * 现金提现
 * @param {*} data
 */
export function money2wechat(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/user/money2wechat", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                window.location.reload();
            }
            message.info(res.data.msg);
        });
    };
}

/**
 * 会员卡信息
 */
export function vipCardInfo() {
    return (dispatch) => {
        Axios.post(Conf.api + "/login/profit").then((res) => {
            if (!res.data.code) {
                dispatch({ type: VIP_CARD, vipCard: res.data.data });
            }
        });
    };
}

export function showModals(args) {
    return showModal(args);
}

/**
 * 每个月的收益列表
 * @param {*} data
 */
export function getProfitByMonth(data) {
    return (dispatch) => {
        dispatch({ type: PROFIT_MONTH, profit_month: null });
        Axios.post(Conf.api + "/user/profit", {
            ...data,
        }).then((res) => {
            if (!res.data.code) {
                dispatch({ type: PROFIT_MONTH, profit_month: res.data.data });
            }
        });
    };
}

/**
 * 余额充值
 * @param {*} data
 */
export function createPayMoneyOrder(data) {
    return createPublicOrder(data);
}

//会员升级
export function upgradeVip(data) {
    return (dispatch) => {
        Axios.post(Conf.api + "/v2/user/upgrade", data).then((res) => {
            if (!res.data.code) {
                if (res.data.data && res.data.data.out_trade_no) {
                    window.location.href =
                        "/#/order/" + res.data.data.out_trade_no;
                } else {
                    message.info(res.data.msg);
                }
            }
        });
    };
}

/**
 * 激活会员卡
 * @param {*} args
 */
export function activeCard(args) {
    return (dispatch) => {
        Axios.post(Conf.api + "/user/activeCard", args).then((res) => {
            if (res.data.code) {
                if (res.data.code === 201) {
                    Modal.success({
                        content:
                            "恭喜会员卡激活成功，会员有效期为：" + res.data.msg,
                        onOk() {
                            const router = new HashRouter();
                            router.history.push("/member/upgrade");
                        },
                    });
                } else {
                    message.info(res.data.msg);
                }
            }
        });
    };
}
