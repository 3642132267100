import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, InputNumber, Card, message, Modal } from "antd";
import { Link } from "react-router-dom";

class Login extends Component {
    formRef = React.createRef();
    formRef2 = React.createRef();
    constructor() {
        super();
        this.state = {
            loading: false,
            money: 0,
            user_money: 0,
        };
    }

    //旗币提现
    onChange = (v) => {
        let value = parseInt(v);
        this.setState({ money: (value / 10).toFixed(2) });
        this.formRef.current.setFieldsValue({ score: value });
    };

    //现金提现
    onChange2 = (v) => {
        let value = parseInt(v);
        this.setState({ money2: (value / 10).toFixed(2) });
        this.formRef2.current.setFieldsValue({ user_money: value });
    };

    componentDidMount() {
        this.props.closeDialog();
    }

    onFinish = (values) => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
        let { userInfo } = this.props.user;
        if (!userInfo["wechatAuth"]) {
            this.wechat();
        } else {
            this.props.scoreToMoney(values);
        }
    };

    wechat = () => {
        var useragent = window.navigator.userAgent.toLowerCase();
        if (useragent.match(/MicroMessenger/i) == "micromessenger") {
            Modal.confirm({
                title: "绑定提现微信号",
                content:
                    "第一次提现，请你先绑定个人微信号，提现申请审核通过后，你的提现金额将直接支付到你所绑定的微信钱包。",
                onOk() {
                    const callback = encodeURIComponent(
                        `http://www.qiniangniang.com/#/welogin`
                    );
                    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf9770f0d25a2c31a&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                    window.location.href = url;
                },
            });
        } else {
            message.info("为保证提现安全，仅限公众号内操作");
        }
    };

    onFinish2 = (values) => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
        let { userInfo } = this.props.user;
        if (!userInfo["wechatAuth"]) {
            this.wechat();
        } else {
            this.props.money2wechat(values);
        }
    };

    render() {
        let { userInfo } = this.props.user;
        let { wallet } = this.props.user;
        let { money } = this.state;
        return (
            <div style={{ paddingBottom: 20 }} className="tixianpage">
                {userInfo && wallet ? (
                    <div>
                        <Card
                            title="旗币兑换"
                            extra={<span>旗币余额：{userInfo.user_score}</span>}
                        >
                            <Form
                                layout="inline"
                                ref={this.formRef}
                                style={{ paddingTop: 10 }}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    name="score"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入提现旗币!",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        onClick={(e) => {
                                            e.target.focus();
                                        }}
                                        placeholder="最小提现1000旗币"
                                        style={{ width: "100%" }}
                                        min={1000}
                                        max={userInfo.user_score}
                                        onChange={this.onChange}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        disabled={
                                            this.state.loading ||
                                            userInfo.user_score < 1000
                                                ? true
                                                : false
                                        }
                                    >
                                        {money ? (
                                            <span>(￥{money}) </span>
                                        ) : null}{" "}
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div style={{ paddingTop: 15, textAlign: "right" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: wallet.scoreTip,
                                    }}
                                ></div>
                            </div>
                        </Card>
                        <Card
                            title="现金提现"
                            extra={<span>现金余额：{userInfo.user_money}</span>}
                            style={{ marginTop: 15 }}
                        >
                            <Form
                                layout="inline"
                                ref={this.formRef2}
                                style={{ paddingTop: 10 }}
                                onFinish={this.onFinish2}
                            >
                                <Form.Item
                                    name="user_money"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入提现金额!",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        onClick={(e) => {
                                            e.target.focus();
                                        }}
                                        placeholder="最小提现100元"
                                        style={{ width: "100%" }}
                                        min={100}
                                        max={userInfo.user_money}
                                        onChange={this.onchange2}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        disabled={
                                            this.state.loading ||
                                            userInfo.user_money < 100
                                                ? true
                                                : false
                                        }
                                    >
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div style={{ paddingTop: 15, textAlign: "right" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: wallet.moneyTip,
                                    }}
                                ></div>
                            </div>
                        </Card>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default connect(
    (state) => state,
    (dispatch) => bindActionCreators(actionsCreators, dispatch)
)(Login);
